import Ticker from 'framer-motion-ticker';
import { useMedia } from 'react-use';
import { Bank } from '@hungryroot/icons';
import {
  Heading,
  HeadingVariant,
  Paragraph,
  ParagraphVariant,
} from '@hungryroot/ramen';
import { BreakpointQueryLarge } from '@hungryroot/tokens';
import { BudgetIcon, BudgetIconVariant } from './BudgetIcon';
import styles from './BudgetSection.module.css';

interface TickerProduct {
  img: {
    sources: Array<{
      srcSet: string;
      media: string;
    }>;
    src: string;
    srcset: string;
    height: number;
    width: number;
  };
  brand: string;
  name: string;
  color: string;
}

const containerPricing = [
  {
    name: 'Breakfast',
    price: '$3.99',
  },
  {
    name: 'Lunch',
    price: '$5.99',
  },
  {
    name: 'Dinner',
    price: '$8.99',
  },
  {
    name: 'Snacks',
    price: '$1.99',
  },
  {
    name: 'Sweets',
    price: '$1.99',
  },
  {
    name: 'Drinks',
    price: '$3.99',
  },
  {
    name: 'Supplements',
    price: '$0.49',
  },
];

interface Props {
  products: TickerProduct[];
}

export function BudgetSection(props: Props) {
  const { products } = props;

  const isDesktop = useMedia(BreakpointQueryLarge, false);

  return (
    <section className={styles.container}>
      <Heading variant={HeadingVariant.HeroAlt} className={styles.heading}>
        Built for your budget
      </Heading>

      <div className={styles['container-inner']}>
        <div className={styles.item}>
          <div className={styles['item-heading']}>
            <Bank className={styles.icon} />
            <Heading
              className={styles['item-heading-text']}
              variant={HeadingVariant.H2}
            >
              Buy more, save more{' '}
              <span className={styles.transparent}>with prices as low as:</span>
            </Heading>
          </div>
          <ul className={styles.table}>
            {containerPricing.map((container) => (
              <li className={styles.row} key={container.name}>
                <Paragraph variant={ParagraphVariant.Lead}>
                  {container.name}
                </Paragraph>
                <Heading variant={HeadingVariant.H4}>{container.price}</Heading>
              </li>
            ))}
          </ul>
          <div className={styles.disclaimer}>
            <Paragraph variant={ParagraphVariant.Caption}>
              Priced per serving
            </Paragraph>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles['item-heading']}>
            <BudgetIcon
              variant={BudgetIconVariant.ValueProp}
              className={styles.icon}
            />
            <Heading
              className={styles['item-heading-text']}
              variant={HeadingVariant.H2}
            >
              <span className={styles.transparent}>Brands you love at the</span>{' '}
              best prices you’ll find.
            </Heading>
          </div>
          <div className={styles.ticker}>
            <Ticker duration={55}>
              {products.map((product) => {
                const {
                  img: {
                    sources: imageSources,
                    srcset: imageSrcSet,
                    src: imageSource,
                    height: imageHeight,
                    width: imageWidth,
                  },
                } = product;
                return (
                  <div
                    className={styles.product}
                    style={{ background: product.color }}
                    key={product.name}
                  >
                    <picture>
                      {imageSources.map(({ media, srcSet }) => {
                        return (
                          <source key={srcSet} media={media} srcSet={srcSet} />
                        );
                      })}
                      <img
                        srcSet={imageSrcSet}
                        width={imageWidth}
                        height={imageHeight}
                        loading="lazy"
                        alt=""
                        className={styles['product-image']}
                        src={imageSource}
                      />
                    </picture>
                    <div className={styles['product-copy']}>
                      <Paragraph
                        className={styles['product-heading']}
                        variant={
                          isDesktop
                            ? ParagraphVariant.CaptionXS
                            : ParagraphVariant.Caption
                        }
                      >
                        {product.brand}
                      </Paragraph>
                      <Heading variant={HeadingVariant.H7}>
                        {product.name}
                      </Heading>
                    </div>
                  </div>
                );
              })}
            </Ticker>
          </div>
        </div>
      </div>
    </section>
  );
}
